<template>
  <div>
    <v-alert
      :type="errorType"
      transition="slide-y-reverse-transition"
      v-model="showalert"
      dismissible
      @click="removeErrors"
      >{{ errorMessage }}</v-alert
    >
  </div>
</template>

<script>
export default {
  data() {
    return { showalert: false };
  },
  props: {
    errorType: {
      type: String,
      default: "error" //"success"
    },
    errorMessage: String,
    alert: { type: Boolean, default: false }
  },
  mounted() {},
  watch: {
    /**
     * Show the alert when value changes
     */
    alert: function(oldval, newval) {
      console.log(newval);
      this.showalert = oldval;
      if (oldval) {
        setTimeout(this.removeErrors, 5000);
      }
    }
  },
  methods: {
    removeErrors() {
      this.showalert = false;
    }
  }
};
</script>
